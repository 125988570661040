import { ModalActionType, useModalContext } from "../../context/modal";

import Button from "../buttons/button/Button";
import Close from "../images/icons/Close";
import SearchButton from "../buttons/searchButton/SearchButton";
import slsFlagCircle from "../../resources/images/random/sls_flag_circle.svg";
import styles from "./OverlaySearchBar.module.scss";
import { useMapContext } from "../../context/map";
import { useMobileState } from "../../hooks/useMobileState";

export default function OverlaySearchBar() {
    const { isMobile } = useMobileState();
    const { lifeguardedFilterActive, setLifeguardFilter, map } =
        useMapContext();
    const { lifeguardModalOpen, showModal } = useModalContext();
    const onLifeguardedFilterClick = () => {
        if (map && !lifeguardedFilterActive) {
            const zoom = map.getZoom();
            if (zoom && zoom > 11) {
                map.setZoom(11);
            }
        }
        if (!lifeguardedFilterActive) {
            // showModal(ModalActionType.LIFEGUARD_MODAL);
        }
        setLifeguardFilter(!lifeguardedFilterActive);
    };

    return (
        <div className={styles.main} key={"search-bar"}>
            <div className={styles.container}>
                {isMobile && <SearchButton />}
                <Button
                    className={styles.sls_button}
                    color={lifeguardedFilterActive ? "secondary" : "white"}
                    variant="filled"
                    size="lg"
                    icon={lifeguardedFilterActive ? <Close /> : slsFlagCircle}
                    iconPosition="left"
                    iconSize="md"
                    shadow
                    onClick={onLifeguardedFilterClick}
                >
                    {lifeguardedFilterActive ? "Show all" : "See lifeguarded"}
                </Button>
            </div>
        </div>
    );
}
